import { action, thunk, effect } from 'easy-peasy'
import themeConfig from '@configs/themeConfig'  
 
const modelEmployee  =  {
 
      // prepare variable for storing list of records
      data: {
        listData: [],
        total: 0,
        pageSize: 1
      },  

      // define variables for keep current parameters
      params: {  
        search: '',
        companycode: '',
        paging: true,  
        skip: 0,
        take: 10,
        orderBy: 'EmployeeId ASC', 
      },  

      // temporary current data that user process
      currentData: {},

      // last information for accessing web API keep in here
      api: {
        success: true,
        message: ''
      },

      // function for setup parameters 
      setParams: action(async(state, payload) => {  
          state.params = {...state.params, ...payload}
      }),
      
      setData: action(async(state, payload) => {  
          state.data = {...state.data, ...payload}
      }),

      setCurrentData: action(async(state, payload) => {  
          state.currentData = {...state.currentData, ...payload}
      }),
  
      resetCurrentData: action(async(state, payload) => {  
        state.currentData = {}
      }), 
 
      getListData: thunk(async (actions, payload,  { getState, injections }) => { 
        const { CommonService } = injections
        try { 
             
          let p = getState().params;
          let q =  {
            query: 'list_employees',
            variables: JSON.stringify({
              companycode: p.companycode,
              search: p.search
            }),
            paging: true,
            skip: p.skip,
            take: p.take,
            orderby: p.orderby
          }
       
          let response = await CommonService.BrowseData(q);  
          console.log(response);
          return true;

        } catch {} 
        return false
      }), 

}  

export default modelEmployee;
