import request from './httpclient'
const qs = require('querystring')

const app_name = 'jasminlive'

async function UploadAvatar(query, data) {
  let dataParam = qs.stringify({
      query: query,
      variables: data
  })
  let token =  GetData("x-token")
  return request({
    url:    '/api/common/uploadphoto',
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
       'x-token': token
    },
    data: dataParam
  })
}

// POS common
function GraphqlData(query, v){
  var param = {
    appname: app_name,
    query: query 
  }

  if (v != undefined && v != null) {
    param.variables = JSON.stringify(v)
  }

  return request({
    url:    'api/common/graphql',
    method: 'POST',
    data: param
  })
}

function BrowseData(param) {
  return request({
    url:    '/api/core/function',
    method: 'POST',
    data: param
  })
}   

function BrowseDataPublic(param) {
  return request({
    url:    '/api/core/fnpublic',
    method: 'POST',
    data: param
  })
}   

function RemoveData(key) {
  localStorage.removeItem(key)
}

function SetData(key, value) {
  localStorage.setItem(key, value) 
}

function GetData(key) {
  return  localStorage.getItem(key)
}

const CommonService = {
  UploadAvatar,
  BrowseData, 
  BrowseDataPublic,
  RemoveData,
  GetData,
  SetData,
  GraphqlData 
}

export default CommonService