import request from './httpclient' 

function Login(userinfo) {
  return request({
    url:    '/api/token/login',
    method: 'POST',
    data: userinfo
  }, false)
} 

function Logout() {
  return request({
    url:    '/api/token/logout',
    method: 'GET' 
  })
} 

function RefreshToken() {
  return request({
    url:    '/api/token/refreshtoken',
    method: 'GET' 
  })
} 

function SignUp(userinfo) {
    return request({
      url:    '/api/token/register',
      method: 'POST',
      data: userinfo
    }, false)
}

function ChangePassword(userinfo) {
    return request({
      url:    '/api/token/changepassword',
      method: 'POST',
      data: userinfo
    })
}

function ForgotPassword(userinfo) { 
  return request({
    url:    '/api/token/forgotpassword',
    method: 'POST',
    data: userinfo
  }, false )
}

function RegisterUserDealer(userinfo) {
  return request({
    url:    '/api/token/RegisterUserDealer',
    method: 'POST',
    data: userinfo
  })
}

function UserClaims() {
    return request({
      url:    '/api/token/claims',
      method: 'GET'
    })
} 

function UserProfile() {
    return request({
      url:    '/api/token/myprofile',
      method: 'GET'
    })
} 
 
function SaveToken (token){
  localStorage.setItem("x-token", token)
}

function RemoveToken (){
  localStorage.removeItem("x-token")
}

const AuthService = {
  Login, SignUp, ChangePassword, SaveToken, 
  RemoveToken, UserClaims, UserProfile, 
  ForgotPassword, RegisterUserDealer,
  RefreshToken, Logout
}

export default AuthService