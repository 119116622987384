import axios from 'axios'

// const BASE_URL = 'https://api.jkt01.ptsis.my.id/'
// const BASE_URL = 'https://idshome.ptsis.my.id/';
const BASE_URL = 'https://api.dev.ptsis.my.id/';

const request = async function (options, isHeader = true, custom_url = BASE_URL) {

  let authHeader = null
  
  if (isHeader) {
    authHeader = localStorage.getItem("x-token")
  } 
  
  let optDefault = {
      baseURL: custom_url,
      headers: { 'x-token': authHeader } , 
      timeout: 60000, 
      maxContentLength: 150 * 1000 * 1000 
  }

  const client = axios.create(optDefault); 

  const onSuccess = function (response) {  
    return response.data
  }

  const onError = function (error) { 
    return Promise.reject(error.response || error.message)
  } 

  return client(options).then(onSuccess).catch(onError)
  
}

export default request