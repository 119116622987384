import { createStore, persist } from 'easy-peasy'   
import AuthService from '@services/auth' 
import CommonService from '@services/common'  
import themeConfig from '@configs/themeConfig'

import modelSesson from './_session'  
import modelEmployee from './_employee'  
 
import { composeWithDevTools } from 'remote-redux-devtools'
const composeEnhancers =  
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?   
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ 
        realtime: true, trace: true
      }) : composeWithDevTools({ 
        realtime: true, trace: true
      })

      
const storeConfig =   
{
    name: themeConfig.app.appName, 
    compose: composeEnhancers,
    injections: {
      AuthService,
      CommonService
    } 
} 

const store = createStore( 
    persist({
        session: modelSesson,
        employee: modelEmployee 
    }),  
    storeConfig
)

export {
  store
}