import { action, thunk, effect } from 'easy-peasy'
import themeConfig from '@configs/themeConfig' 
const MY_LAYOUT = "_LAYOUT" 
 
const modelSession  =  {

      layout: {
        isRTL: themeConfig.layout.isRTL,
        menuCollapsed: false,
        menuHidden: themeConfig.layout.menu.isHidden,
        contentWidth: themeConfig.layout.contentWidth,
        darkMode: false
      },

      language: 'id',
      theme: null,  
      userData: {},  
      isLogin: false,   
      company: 'Jasmine',
 
      setCompany:  action((state, payload) => {
          state.company = payload
      }),  


      setLanguage:  action((state, payload) => {
          state.language = payload
      }),  
 
      setTheme:  action((state, payload) => {
          state.theme = payload
      }), 

      setLayout: action(async(state, payload) => {  
          state.layout = {...state.layout,...payload} 
          window.localStorage.setItem(MY_LAYOUT, JSON.stringify(state.layout))
      }),
  
      handleContentWidth:  action(async(state, payload) => {  
          state.layout = {...state.layout, contentWidth: payload } 
          window.localStorage.setItem(MY_LAYOUT, JSON.stringify(state.layout))  
      }),  

      handleMenuCollapsed:  action(async(state, payload) => {  
          state.layout = {...state.layout, menuCollapsed: payload} 
          window.localStorage.setItem(MY_LAYOUT, JSON.stringify(state.layout))  
      }),  

      handleDarkMode:  action(async(state, payload) => {  
          state.layout = {...state.layout, darkMode: payload} 
          window.localStorage.setItem(MY_LAYOUT, JSON.stringify(state.layout))  
      }),  

      handleMenuHidden:  action(async(state, payload) => {  
        state.layout = {...state.layout, menuHidden: payload} 
        window.localStorage.setItem(MY_LAYOUT, JSON.stringify(state.layout))  
      }), 
      
      handleRTL:  action(async(state, payload) => {  
          state.layout = {...state.layout, isRTL: payload} 
          window.localStorage.setItem(MY_LAYOUT, JSON.stringify(state.layout))  
      }),  

      // user login
      userLogin: thunk(async (actions, payload,  { injections }) => { 
        const { AuthService } = injections
        try { 
            let response = await AuthService.Login(payload)
            if (response != null) {
                if (response.success) {
                    await AuthService.SaveToken(response.token)  
                    let userProfile = await AuthService.UserProfile()
                    // console.log('user profile', userProfile)
                    if (userProfile != null) {
                      if (userProfile.uid !== undefined) {   
                        actions.loginSucceeded(userProfile); 
                        return true
                      }
                    }
                }
            }   
        } catch {} 
        return false
      }),

      // update refreshtoken and get current userinfo
      userReload: thunk(async (actions, payload,  { injections }) => {  
        const { AuthService } = injections 
        let userProfile = null
        try{ 
          await AuthService.RefreshToken()
          userProfile = await AuthService.UserProfile()   
          if (userProfile != null) {
            if (userProfile.uid !== undefined) {   
              actions.loginSucceeded(userProfile)
              return true
            }
          } 
        }catch(e){ } 
        actions.loginSucceeded(userProfile)
        return false
      }),

      userLogout: thunk(async (actions, payload,   { injections }) => {    
        try{ 
            const { AuthService } = injections
            AuthService.RemoveToken() 
            actions.loginSucceeded(null)
            return true 
        }catch(e){} 
        return false
      }),

      checkState: thunk(async (actions, payload) => {  
        let myLayout = window.localStorage.getItem(MY_LAYOUT) 
        if (myLayout !== null && myLayout !== undefined) {
          actions.setLayout(JSON.parse(myLayout))
        }
      }), 

      loginSucceeded:  action(async(state, payload) => {
          if (payload == null) {
            state.userData = {}
            state.isLogin = false 
          } else {
            state.userData = {...state.userData,...payload} 
            state.isLogin = true 
          }
       })  

}  

export default modelSession;
